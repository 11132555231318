import { PSUserInfo } from '@/app/types/user';
import { AvatarProps } from '@radix-ui/react-avatar';
import { Icons } from '@/app/components/Icons';
import { Avatar, AvatarFallback, AvatarImage } from '@/app/ui/avatar';

interface UserAvatarProps extends AvatarProps {
  user: Pick<PSUserInfo, 'name' | 'image' | 'email' | 'role'>;
}

export function UserAvatar({ user, ...props }: UserAvatarProps) {
  return (
    <Avatar {...props}>
      {user.image ? (
        <AvatarImage alt="Picture" src={user.image} />
      ) : (
        <AvatarFallback className="bg-[#FF6B2B]">
          <span className="sr-only">{user.name}</span>
          <Icons.user className="h-4 w-4 bg-[#FF6B2B]" color="#ffffff" />
        </AvatarFallback>
      )}
    </Avatar>
  );
}
