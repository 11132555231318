'use client';
import { PSUserInfo } from '../types/user';
import { Session } from 'next-auth';

export function composeSessionUser(session: Session): PSUserInfo | null {
  if (
    !session.user ||
    !session.user.email ||
    !session.user.id ||
    !session.user.name
  ) {
    return null;
  }
  const userInfo: PSUserInfo = {
    id: session.user.id,
    email: session.user.email,
    name: session.user.name,
    role: session.role,
    membershipExpire: session.membershipExpire,
  };
  return userInfo;
}
