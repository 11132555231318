export default function CrownIcon({ className }: { className?: string }) {
    return (
      <svg
        className="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="4099"
        width="16"
        height="16"
      >
        <path
          d="M755.685018 820.532142H274.077014a93.790328 93.790328 0 0 1-90.393092-68.544209L69.37696 344.586197a16.32005 16.32005 0 0 1 21.182758-19.983735l265.583833 93.257428 145.414975-325.535036a16.386662 16.386662 0 0 1 29.709153-0.399675l157.072153 326.400998 244.201237-102.116883a16.32005 16.32005 0 0 1 22.115333 19.184385l-109.311028 419.658426a93.923553 93.923553 0 0 1-89.660356 65.480037z"
          fill="#FFF100"
          p-id="4100"
        ></path>
        <path
          d="M932.541069 319.806366L906.895276 330.53097l-124.964953 490.001172a93.790328 93.790328 0 0 0 63.548276-62.016189l109.311028-419.658427a16.386662 16.386662 0 0 0-22.248558-19.05116z"
          fill="#F8B62D"
          p-id="4101"
        ></path>
        <path
          d="M92.624704 319.806366l25.779018 10.791217 124.898341 490.001172a93.85694 93.85694 0 0 1-63.481664-62.01619l-109.37764-419.658426A16.386662 16.386662 0 0 1 92.624704 319.806366z"
          fill="#FFFFFF"
          p-id="4102"
        ></path>
        <path
          d="M756.884042 833.854632H275.342651a107.579105 107.579105 0 0 1-103.249296-78.269627l-17.985361-63.947951a13.32249 13.32249 0 1 1 25.645793-7.194144l17.985361 63.947951a80.867513 80.867513 0 0 0 77.603503 58.818792h481.541391a80.268001 80.268001 0 0 0 76.737541-56.487357l69.743234-267.049306a13.32249 13.32249 0 1 1 25.779017 6.661244l-69.743233 267.648819a106.91298 106.91298 0 0 1-102.516559 75.871579zM147.513362 631.752463a13.32249 13.32249 0 0 1-12.78959-9.725418L57.719781 348.183269a29.64254 29.64254 0 0 1 38.435383-36.037335l253.993267 89.327294L490.567472 86.662796a29.575927 29.575927 0 0 1 26.64498-17.585687 29.043028 29.043028 0 0 1 27.177879 16.85295l151.543321 314.810432 232.544058-97.254175a29.775765 29.775765 0 0 1 39.967469 34.904923L946.862746 421.6568a13.32249 13.32249 0 0 1-25.84563-6.661245l21.649045-83.065723a3.064173 3.064173 0 0 0-4.129971-3.597073l-255.591966 106.91298L520.543074 97.65385a2.664498 2.664498 0 0 0-2.797723-1.731924 2.864335 2.864335 0 0 0-2.73111 1.865149l-150.544134 336.925765L87.562158 337.258827a2.864335 2.864335 0 0 0-3.064173 0.666125 2.99756 2.99756 0 0 0-0.865961 3.064173l76.604316 273.843776a13.32249 13.32249 0 0 1-9.259131 16.386662 11.190891 11.190891 0 0 1-3.463847 0.5329z"
          fill="#231815"
          p-id="4103"
        ></path>
        <path
          d="M203.401206 910.125886m50.225787 0l519.044199 0q50.225786 0 50.225787 50.225786l0 0.066613q0 50.225786-50.225787 50.225786l-519.044199 0q-50.225786 0-50.225787-50.225786l0-0.066613q0-50.225786 50.225787-50.225786Z"
          fill="#00A0E9"
          p-id="4104"
        ></path>
        <path
          d="M771.472168 910.125886h-35.504435a50.292399 50.292399 0 0 1 0 100.518185h35.504435a50.292399 50.292399 0 1 0 0-100.518185z"
          fill="#036EB8"
          p-id="4105"
        ></path>
        <path
          d="M252.494581 910.125886h35.504435a50.292399 50.292399 0 0 0 0 100.518185h-35.504435a50.292399 50.292399 0 0 1 0-100.518185z"
          fill="#FFFFFF"
          p-id="4106"
        ></path>
        <path
          d="M771.472168 1023.966561h-35.504435a13.32249 13.32249 0 0 1 0-26.64498h35.504435a36.969909 36.969909 0 1 0 0-73.873205H252.494581a36.969909 36.969909 0 0 0 0 73.873205h347.250695a13.32249 13.32249 0 1 1 0 26.64498H252.494581a63.614888 63.614888 0 0 1 0-127.163165h518.977587a63.614888 63.614888 0 1 1 0 127.163165z"
          fill="#231815"
          p-id="4107"
        ></path>
        <path
          d="M687.473871 1023.966561h-37.769259a13.32249 13.32249 0 0 1 0-26.64498h37.769259a13.32249 13.32249 0 0 1 0 26.64498z"
          fill="#231815"
          p-id="4108"
        ></path>
        <path
          d="M513.149093 82.599436m-69.210335 0a69.210334 69.210334 0 1 0 138.420669 0 69.210334 69.210334 0 1 0-138.420669 0Z"
          fill="#FFFFFF"
          p-id="4109"
        ></path>
        <path
          d="M513.149093 165.198873a82.599436 82.599436 0 1 1 82.599436-82.599437 82.666049 82.666049 0 0 1-82.599436 82.599437z m0-138.487281a55.954457 55.954457 0 1 0 55.954456 55.887844A55.954457 55.954457 0 0 0 513.149093 26.711592z"
          fill="#231815"
          p-id="4110"
        ></path>
        <path
          d="M955.255914 305.751139m-54.42237 0a54.422371 54.422371 0 1 0 108.844741 0 54.422371 54.422371 0 1 0-108.844741 0Z"
          fill="#FFFFFF"
          p-id="4111"
        ></path>
        <path
          d="M955.255914 373.562612a67.811473 67.811473 0 1 1 67.74486-67.811473 67.944698 67.944698 0 0 1-67.74486 67.811473z m0-108.911354a41.166493 41.166493 0 1 0 41.099881 41.099881 41.166493 41.166493 0 0 0-41.099881-41.099881z"
          fill="#231815"
          p-id="4112"
        ></path>
        <path
          d="M68.710835 325.734874m-54.42237 0a54.422371 54.422371 0 1 0 108.844741 0 54.422371 54.422371 0 1 0-108.844741 0Z"
          fill="#FFFFFF"
          p-id="4113"
        ></path>
        <path
          d="M68.710835 393.546347a67.811473 67.811473 0 1 1 67.811473-67.811473 67.878085 67.878085 0 0 1-67.811473 67.811473z m0-108.911354a41.166493 41.166493 0 1 0 41.166493 41.099881 41.166493 41.166493 0 0 0-41.166493-41.099881z"
          fill="#231815"
          p-id="4114"
        ></path>
      </svg>
    );
  }
  