export const ONE_DAY = 3600 * 24;
export const MEMBERSHIP_MONTHLY = 2; // 月度会员的值 The value for monthly membership
export const MEMBERSHIP_NEW = 0;
export const MEMBERSHIP_SUSPEND = 1;

export const INTRO_ASC_TRIANGLE =
  'An ascending triangle is a bullish chart pattern characterized by a horizontal upper trendline and a rising lower trendline. This pattern suggests that buying pressure is increasing as the price makes higher lows. The horizontal resistance level indicates a strong selling pressure at that price point, while the rising support level shows that buyers are stepping in to prevent the price from falling below a certain level. A breakout above the upper trendline is considered a bullish signal, indicating that the price is likely to continue its upward trend.';
export const INTRO_DESC_TRIANGLE =
  'A descending triangle is a bearish chart pattern that is the opposite of an ascending triangle. It has a horizontal lower trendline and a declining upper trendline. This pattern indicates that selling pressure is increasing as the price makes lower highs. The horizontal support level shows that buyers are still interested in the asset at that price point, but the declining resistance level suggests that sellers are overwhelming buyers. A breakdown below the lower trendline is considered a bearish signal, indicating that the price is likely to continue its downward trend.';
export const INTRO_RECT =
  'A rectangle, also known as a consolidation pattern, is a chart pattern that shows a price range where neither buyers nor sellers have a clear advantage. It is characterized by two nearly horizontal parallel trendlines, one representing the resistance level and the other representing the support level. The price tends to oscillate between these two levels for a period of time. A breakout above the resistance level is considered a bullish signal, while a breakdown below the support level is considered a bearish signal. Rectangles can occur during any trend and can be a period of rest before the price continues its previous trend.';
export const INTRO_TRENDLINE =
  'A trendline is a line drawn by connecting a series of highs or lows on a chart to identify the overall direction of the market. A trendline breakout occurs when the price moves above or below this line, indicating a potential change in the trend. An upward breakout above a resistance trendline signals a bullish shift, suggesting that buyers are overpowering sellers. Conversely, a downward breakout below a support trendline implies a bearish shift, indicating that sellers are dominating. The significance of a trendline breakout depends on the slope of the trendline, the length of time it has been in place, and the accompanying trading volume.';
export const INTRO_FLAG =
  "A flag pattern is a technical analysis chart pattern that typically follows a strong price move and indicates a potential continuation of the existing trend. It's characterized by a brief consolidation period within parallel trendlines, resembling a flag on a flagpole. The height of the pole often provides a measure for projecting the potential price target after the breakout. A bullish flag forms during an uptrend and suggests a continuation of the upward movement, while a bearish flag forms during a downtrend and indicates a continuation of the downward movement. Traders often use flag patterns to identify potential entry points and set profit targets.";
export const INTRO_RS =
  "Support and resistance levels are horizontal lines on a chart that represent price levels where the market has previously struggled to move beyond. Support is a level at which buying interest is strong enough to prevent the price from falling further, while resistance is a level where selling pressure is strong enough to prevent the price from rising further. A breakout above a resistance level or below a support level can signal a significant shift in market sentiment. However, false breakouts can occur, so it's important to confirm the breakout with additional indicators or price action.";
export const INTRO_DOUBLE_EX =
  'Double tops and bottoms are reversal patterns that indicate a potential change in the direction of the current trend. A double top is formed when the price reaches two similar highs and then reverses downward, breaking below a neckline. This pattern suggests that the buying momentum has exhausted, and sellers are now in control. A double bottom is the opposite, with the price forming two similar lows and then reversing upward, breaking above a neckline. This pattern indicates that the selling pressure has eased, and buyers are now in control.';
export const INTRO_HS =
  'The head and shoulders pattern is another reversal pattern similar to the double top and bottom. It consists of a left shoulder, a head (the highest point), a right shoulder, and a neckline. A head and shoulders top is a bearish pattern, indicating a potential downward trend, while a head and shoulders bottom is a bullish pattern, indicating a potential upward trend. The neckline is a crucial level, and a breakout below the neckline for a head and shoulders top or above the neckline for a head and shoulders bottom confirms the pattern.';
export const INTRO_DIV_RSI =
  'The Relative Strength Index (RSI) is a momentum oscillator that measures the speed and change of price movements. RSI divergence occurs when the price and the RSI move in opposite directions. A bullish divergence happens when the price makes a lower low, but the RSI makes a higher low, indicating that buying pressure is increasing relative to selling pressure. A bearish divergence occurs when the price makes a higher high, but the RSI makes a lower high, indicating that selling pressure is increasing relative to buying pressure.';
export const INTRO_DIV_MACD =
  "The Moving Average Convergence Divergence (MACD) is a trend-following momentum indicator that shows the relationship between two moving averages of a security's price. MACD divergence occurs when the MACD line and the signal line move in opposite directions relative to the price. Bullish and bearish divergences in the MACD are interpreted similarly to RSI divergences. ";
export const INTRO_EMA_CROSS =
  'Exponential Moving Averages (EMAs) give more weight to recent prices. An EMA crossover occurs when a shorter-term EMA crosses above or below a longer-term EMA. A bullish crossover signals a potential upward trend, as it suggests that the short-term momentum is stronger than the long-term momentum. A bearish crossover signals a potential downward trend, as it suggests that the short-term momentum is weaker than the long-term momentum.';
export const INTRO_EMA_ALIGN =
  'This pattern usually indicates a strong trend. For the bullish case, this pattern occurs when three moving averages with different time periods (e.g., 5-day, 10-day, and 20-day) align in an ascending order, with the shorter-term moving average leading the longer-term ones. This suggests a strong bullish trend and is often seen as a buying signal. And the bearish version is the opposite of the bullish one. The three moving averages align in a descending order, with the shorter-term moving average leading the longer-term ones. This indicates a strong bearish trend and is often seen as a selling signal. ';

export const PATTERN_NOTE =
  'No technical analysis pattern is foolproof, and false signals can occur. Therefore, traders should always consider using additional technical indicators and fundamental analysis to make informed trading decisions.';
