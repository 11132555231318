'use client';
import { useMemo } from 'react';
import { SessionProvider, SessionProviderProps } from 'next-auth/react';

interface ProviderProps extends SessionProviderProps {
  sessionKey: number;
}

export function CustomSessionProviders({ children, ...props }: ProviderProps) {
  const { session, sessionKey } = props;
  const memoizedSessionKey = useMemo(() => {
    // console.log('session changed >>> ', session);

    return sessionKey;
  }, [session]);

  return (
    <SessionProvider key={memoizedSessionKey} session={session}>
      {children}
    </SessionProvider>
  );
}
